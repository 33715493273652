import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layout/DefaultLayout';
import PageTitle from '@mangoart/gatsby-ui/components/ezagrar/MeinTraktor-Lamborghini-Same/PageTitle';
import { ContactForm } from '../components/ContactForm/ContactForm';
import { InnerForm } from '../components/ContactForm/InnerForm';
import Image from '../assets/images/Natural_Explorer-Natural-105_Stage-V.jpg';
import * as styles from './anfrage.module.css';
import clsx from 'clsx';

const NewsPage = ({ data, location }) => {
  const { siteData } = data;
  const { siteMetadata } = siteData;
  return (
    <DefaultLayout>
      {/* <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      /> */}
      <PageTitle>
        {/* icon && <Icon icon={icon} /> */}
        Anfrage
      </PageTitle>
      <section className={clsx(styles.formsection, 'defaultWidth')}>
        <div style={{ display: 'flex', gap: 12, width: '100%', alignItems: 'stretch' }}>
          <div className={styles.spacerImage}>
            <img
              src={Image}
              style={{ objectFit: 'cover', width: '100%', height: '100%', objectPosition: 'right' }}
              alt={''}
            />
          </div>
          <div className={styles.formcontainer}>
            <ContactForm form={InnerForm} />
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
};

export default NewsPage;

export const NewsPageQuery = graphql`
  query AnfragePageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
  }
`;
